<template>
  <Dialog :visible="state.visible" :showClose="state.showClose" @close="state.visible = false" @ok="onOk" alert
    :okText="state.okText" :text="state.text"></Dialog>
</template>

<script setup>
import { onMounted, reactive } from 'vue';
import mitt from '@/libs/event';
import Dialog from '../Dialog.vue';
import { switchEthChain } from '@/utils/wallet';
import { chains } from '@/constants';
import { useRouter } from 'vue-router';
import { LOCALSTORAGE_WALLET_CONNECT_CHAIN_TYPE } from '@/constants/walletTypes';

const $router = useRouter();

const state = reactive({
  visible: false,
  text: 'SURE',
  skip: false,
  showClose: false,
  network: '',
  chainId: '',
  text: `Only supports Polygon-Maninnet, Switch now?`,
  okText: 'SURE'
});

const onOk = () => {
  if (state.skip) {
    $router.replace('/create/dashboard');
  } else {
    // if (state.chainId == 8453) {
    //   switchEthChain(chains.Base);
    //   localStorage.setItem(
    //     LOCALSTORAGE_WALLET_CONNECT_CHAIN_TYPE,
    //     chains.Base.chainName,
    //   );
    // }
    if (state.chainId == 84532) {
      switchEthChain(chains.Sepolia);
      localStorage.setItem(
        LOCALSTORAGE_WALLET_CONNECT_CHAIN_TYPE,
        chains.Sepolia.chainName,
      );
    }
    else {
      switchEthChain(chains.Polygon);
      localStorage.setItem(
        LOCALSTORAGE_WALLET_CONNECT_CHAIN_TYPE,
        chains.Polygon.chainName,
      );
    }
  }
};

onMounted(() => {
  mitt.on('network', ({ visible, skip, chainId }) => {
    state.visible = visible;
    state.skip = skip;
    state.text = skip ? 'SKIP' : 'SURE';
    state.showClose = skip ? false : true;
    state.chainId = chainId;
    // if (chainId == 8453) {
    //   state.text = `Only supports ${chains.Base.chainName}-Maninnet, Switch now?`
    // }

    if (chainId == 84532) {
      state.text = `Only supports ${chains.Sepolia.chainName}, Switch now?`
    }
  });
});
</script>

<style lang="scss" scoped></style>
