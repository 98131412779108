// // 老合约
// export const config = {
//     BondingCurveUtil: '0xBc5DCB0eAc49D07DBBC3A9D870aC8EaeF36D529a',
//     LpLocker: '0x9B2c7B9e2176FFF24D881E068f69f9Fc35124D8c',
//     Token: '0xdc0B33F6AFED31d45E2B0119d3e05f787B526684',
//     BondingCurve: '0x6D0Dd453e827A3C89e93c38278e1f28b5D968840',
//     BondingCurveHepler: '0x2E2F8a2b6F658DEe124185F5e4cE1d87928aC8D8',
//     chainId: '8453', 
// }
// // 新合约
// export const config2 = {
//     BondingCurveUtil: '0x7D7DD204b4104057b8Bd141b0b14F22f3d3D5F77',
//     LpLocker: '0x1391A4B8295243f92eA05dd7bd60e7026a36b960',
//     Token: '0xc2e21d0a45cB3b1dC3E70CeD5E4203F8d3E2150A',
//     BondingCurve: '0x43b97b95772f154c00196Fe84cb3352a71fFF274',
//     BondingCurveHepler: '0xeF6925281D188E88cB54ad8eF111C10991dcac38',
//     chainId: '8453', 
// }
// // 新合约 3
// export const config3 = {
//     BondingCurveUtil: '0x829f4078f778083E07400F9Df142E909f27Bcd55',
//     LpLocker: '0xec7f9998bb7E1deBbcf4a8c165928eC3d2253d17',
//     Token: '0x38b8586d3c744028912248A248226627fD604F05',
//     BondingCurve: '0x4b87a145d12ABD92C8A4480eEf9b68b08C0d292f',
//     BondingCurveHepler: '0xbd62f03E970105EbC29D213656aAfb3da547E839',
//     chainId: '8453', 
// }

// start  测试环境
export const config = {
    BondingCurveUtil: '0x0fDEd431900f5809B06A92c1d924AB8344B35F3B',
    LpLocker: '0xD0F96132313E84f86Ca057b3092E1614a6D5638C',
    Token: '0x41d7F53Fda1C3cF737FFCc60bF5a8CFAaB6f92b3',
    BondingCurve: '0x38eF38520B6109fe33FA9f931146266D46064deA',
    BondingCurveHepler: '0xb4CB585a92F876eEfa27E3cEbB8bB62217E60dE1',
    chainId: '84532', 
}

export const config2 = {
    BondingCurveUtil: '0x2B9046899c1075B95aB12B6f34e1028290c56CA8',
    LpLocker: '0xcbaa3C662Aa5c267409F1C142f49f8128724151F',
    Token: '0x64AF76461E8C8D23B19D888735C010e11A4a7b0e',
    BondingCurve: '0x083aBE848B054075D109FDe3625389d56789da22',
    BondingCurveHepler: '0xC2caC7243089183550BeA4475dA4958Fa1E34C6C',
    chainId: '84532', 
}

// 新合约 3
export const config3 = {
    BondingCurveUtil: '0x5C2AB18C2824D6Fefe17d5475acdE2e348DA8e4b',
    LpLocker: '0x2F9Fe8f05519b12A843fC4A607e355202f85A2e2',
    Token: '0xE0BCE35C13218738888A6Da8C0582493c9A3678e',
    BondingCurve: '0x7abc827eE4195707D6CB8b9B1a468362Ab95A083',
    BondingCurveHepler: '0x8B068e89A0Ae1e36d94EA1cAeDBFD979802db18D',
    Referral: "0x105D69F16e0F6f979fA9deBcfFAEA57D7d8041f9", // 与我无关
    chainId: '84532', 
}
// end
