// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/mintbadge/small.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mobile-box--shadow[data-v-aa481b80] {\n  box-shadow: 0px -8px 8px 0px rgba(255, 255, 255, 0.05);\n}\n.mobile-tab--btn[data-v-aa481b80]:active {\n  background: rgba(255, 255, 255, 0.1019607843);\n}\n.mobile-network--line[data-v-aa481b80] {\n  border: 1px solid #ff5585;\n}\n.notMintDotted[data-v-aa481b80] {\n  width: 10px;\n  height: 10px;\n  border-radius: 100%;\n  background: #ff5585;\n  right: -5px;\n  top: 5px;\n}\n.profileIconBadgeDotted[data-v-aa481b80] {\n  width: 6px;\n  height: 6px;\n  border-radius: 100%;\n  background: #ff5585;\n  right: 0;\n  top: 4px;\n}\n.daoBadgeButton[data-v-aa481b80] {\n  padding: 0 8px;\n  height: 20px;\n  border-radius: 10px;\n  font-size: 0.6rem;\n}\n.daoBadgeIcon[data-v-aa481b80] {\n  width: 10px;\n  height: 10px;\n  border-radius: 10px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center;\n  background-size: 100%;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
