// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/icon_close.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".close[data-v-43a3e4e8] {\n  width: 1rem;\n  height: 1rem;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n}\n.notify-box[data-v-43a3e4e8] {\n  border-radius: 8px;\n  border: 1px solid #292c2e;\n}\n.notify-success[data-v-43a3e4e8] {\n  background: linear-gradient(90deg, #3c8542 0%, #121416 100%);\n}\n.notify-error[data-v-43a3e4e8] {\n  background: linear-gradient(90deg, #852a35 0%, #121416 100%);\n}\n.notify-normal[data-v-43a3e4e8] {\n  background: linear-gradient(90deg, #135c8b 0%, #121416 100%);\n}\n.icon-gou[data-v-43a3e4e8] {\n  color: rgba(94, 255, 106, 1);\n}\n.icon-jinggao[data-v-43a3e4e8] {\n  color: rgba(255, 38, 64, 1);\n}\n.icon-tishi[data-v-43a3e4e8] {\n  color: rgba(38, 170, 255, 1);\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
