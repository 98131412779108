// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/mintbadge/small.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lighter-text[data-v-b5d1a4a6]:hover {\n  color: white;\n}\n.x-menu--item[data-v-b5d1a4a6] {\n  display: flex;\n  align-items: center;\n}\n.x-menu--item.text-whitebase[data-v-b5d1a4a6] {\n  color: #fff;\n}\n.notMintDotted[data-v-b5d1a4a6] {\n  width: 12px;\n  height: 12px;\n  border-radius: 100%;\n  background: #ff5585;\n  right: 0;\n  top: 0;\n}\n.profileIconBadgeDotted[data-v-b5d1a4a6] {\n  width: 6px;\n  height: 6px;\n  border-radius: 100%;\n  background: #ff5585;\n  right: 0;\n  top: 4px;\n}\n.daoBadgeButton[data-v-b5d1a4a6] {\n  padding: 0 8px;\n  height: 20px;\n  border-radius: 10px;\n  font-size: 0.6rem;\n}\n.daoBadgeIcon[data-v-b5d1a4a6] {\n  width: 10px;\n  height: 10px;\n  border-radius: 10px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center;\n  background-size: 100%;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
