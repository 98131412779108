<template>
  <div v-if="!isHidden" class="modal">
    <div class="banner">
      <div class="flex justify-end">
        <div class="absolute close-container cursor-pointer" @click="closeDialog">
          <i class="iconGuanbi iconfont icon-guanbi absolute text-white-080 z-top"></i>
        </div>
      </div>
      <div class="md:mt-16 w-full h-24 md:h-60 cursor-pointer" 
        @click="jumpPointTask">
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { handleDcVerify, handleFcVerify, handleTgVerify, handleTwVerify, isMobilePage } from '@/utils';
import { useDesktop, useReqByBool } from '@/hooks';
import { notify } from '@kyvg/vue3-notification';
import { useRouter } from 'vue-router';

const $router = useRouter();
const isMobile = isMobilePage();
const isDesktop = useDesktop();
const store = useStore();

const isHidden = ref(true);

const jumpPointTask = () => {
  localStorage.setItem('hideCorner1', 'true');
  isHidden.value = true;
  $router.push('/point/task');
}

const closeDialog = () => {
  localStorage.setItem('hideCorner1', 'true');
  isHidden.value = true;
}

onMounted(() => {
  const hideCorner1 = localStorage.getItem('hideCorner1');
  console.log('hideCorner1::', hideCorner1);
  if (!hideCorner1) {
    // localStorage.setItem('hideCorner2', 'true');
    isHidden.value = false;
  }
});

</script>

<style lang="scss" scoped>
@media screen and (max-width: 800px) {
  .banner {
    width: 85% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 999;
    padding: 50px !important;
    background-image: url('~@/assets/images/point_blast.png');
    background-size: cover;
  }
  .desc {
    width: 100% !important;
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .banner {
    height: auto !important;
  }
  .cancel-button {
    padding: 8px 12px !important;
    margin-top: 10px;
    font-size: 12px;
  }
  .mb-red-button {
    padding: 8px 12px !important;
    margin-top: 10px;
    font-size: 12px;
  }
  .active-button {
    padding: 8px 12px !important;
    margin-top: 10px;
    font-size: 12px;
  }
  .close-container {
    width: 32px !important;
    height: 32px !important;
    right: 5px !important;
    top: 5px !important;
  }
  .iconGuanbi {
    right: 0px !important;
    top: 0px !important;
  }
}
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
}
.banner {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-400px, -150px);
  width: 750px;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  padding: 32px;
  height: 425px;
  background-image: url('~@/assets/images/point_blast.png');
  background-size: cover;
}
.bannerImage {
  width: 100%;
}
.close-container {
  width: 64px;
  height: 64px;
  right: 10px;
  top: 10px;
}
.iconGuanbi {
  right: 24px;
  top: 24px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  font-size: 24px;
  font-weight: normal;
  //background: rgba(255, 255, 255, 0.2);
}
.desc {
  text-align: center;
  margin-top: 32px;
  width: 80%;
  font-size: 16px;
  color: #ffffff;
  line-height: 24px;
}
.cancel-button {
  padding: 8px 24px;
  margin-top: 16px;
}
.mb-red-button {
  padding: 8px 24px;
  margin-top: 16px;
}
.active-button {
  padding: 8px 24px;
  margin-top: 16px;
}
</style>
